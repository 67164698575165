import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { formatDate } from "../utils/dateUtils";
import UserDataService from "../../services/users";

const UserEdit = ({ id }) => {
  //const { user_c } = useContext(UserContext);
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    role: 0,
    email: "",
    firstname: "",
    lastname: "",
    username: "",
    phone: "",
  });

  const successToast = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorToast = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const retrieveUser = useCallback(() => {
    //console.log("get drivers");
    if (user.role !== -2) {
      UserDataService.getUser(id)
        .then((response) => {
          const user = response.data;
          setUser(user);
          setFormData((prevState) => ({
            ...prevState,
            ...user,
          }));
        })
        .catch((e) => {
          console.log(e);
          errorToast(t("Error.We_can_not_contact_the_server"));
        });
    }
  }, [user.role, id, t]);

  useEffect(() => {
    retrieveUser();
  }, [retrieveUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlerrors = (errors, inputValues) => {
    // Validation logic

    if (!inputValues.firstname) {
      errors.firstname = t("Error.Firstname_is_required");
    }

    if (!inputValues.lastname) {
      errors.lastname = t("Error.Lastname_is_required");
    }

    if (!inputValues.username) {
      errors.username = t("Error.Username_is_required");
    }

    if (!inputValues.email) {
      errors.email = t("Error.Email_is_required");
    } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
      errors.email = t("Error.Email_must_be_a_valid_email");
    }

    if (!inputValues.phone) {
      errors.phone = t("Error.Phone_is_required");
    }

    return errors;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    //console.log(formData);

    let errors = {};
    errors = handlerrors(errors, formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      //axiooooooooooooooooooooooooooooooos
      console.log("Before sending updated formData");
      try {
        const { data } = await UserDataService.updateUser(id, formData);
        const { success, message, user } = data;
        if (success) {
          successToast(t("Message.User_updated_successfully"));
          console.log("success:", message);
          setUser({ ...user });
        } else {
          errorToast(t("Message.The_server_could_not_manage_the_request"));
          console.log("Not success:", message);
        }
      } catch (error) {
        errorToast(t("Message.We_can_not_contact_the_server"));
        console.log(error);
      }
    } else {
      errorToast(t("Message.There_are_errors_in_the_form"));
      //console.log(errors)
    }
  };

  const updateUserRole = async (id, roleString) => {
    try {
      // Mapping from string descriptions to integers
      const roleMap = {
        deleted: -2,
        frozen: -1,
        created: 0,
        admin: 1,
        driver: 2,
      };

      // Convert the role description to integer using the mapping
      const role = roleMap[roleString.toLowerCase()];

      // Define allowed roles as integers
      const allowedRoles = [-2, -1, 0, 1, 2];

      // Check if the converted role is in the allowed roles
      if (!allowedRoles.includes(role)) {
        errorToast(
          `Role '${roleString}' with integer value '${role}' is not allowed.`
        );
        console.error(
          `Role '${roleString}' with integer value '${role}' is not allowed.`
        );
        return; // Exit the function early if role is not allowed
      }

      const data = { role };

      console.log("Before sending updated role data", data);

      const response = await UserDataService.updateUserRoleByAdmin(id, data);

      if (response.status === 201) {
        // Update the local state to reflect the new status
        setFormData({ ...formData, role });
        successToast(
          response.data.message || t("Message.Role_updated_successfully")
        );
      } else {
        // Handle the case where the server responds, but the update is unsuccessful
        errorToast(response.data.message || t("Message.Updated_failed"));
      }
    } catch (error) {
      // Handle any errors during the request
      errorToast(t("Message.Error_updating_role"));
      console.error("Error during status update:", error);
    }
  };

  return (
    <div className="text-white">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              Driver role
            </h2>
            <div className="mx-auto grid grid-cols-2 md:grid-cols-6">
              <button
                type="button"
                disabled={formData.role === 0 && true}
                onClick={() => updateUserRole(id, "created")}
                className="disabled:text-slate-500 md:col-span-1 rounded-md bg-green-300 mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                {t("User.just_created")}
              </button>
              <button
                type="button"
                disabled={formData.role === 1 && true}
                onClick={() => updateUserRole(id, "admin")}
                className="disabled:text-slate-500 md:col-span-1 rounded-md bg-[#00df9a] mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                {t("User.admin")}
              </button>
              <button
                type="button"
                disabled={formData.role === 2 && true}
                onClick={() => updateUserRole(id, "driver")}
                className="disabled:text-slate-500 md:col-span-1 rounded-md bg-blue-400 mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                {t("User.driver")}
              </button>
              <button
                type="button"
                disabled={formData.role === -1 && true}
                onClick={() => updateUserRole(id, "frozen")}
                className="disabled:text-slate-500 md:col-span-1 rounded-md bg-orange-300 mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                {t("User.frozen")}
              </button>
              <button
                type="button"
                disabled={formData.role === -2 && true}
                onClick={() => updateUserRole(id, "deleted")}
                className="disabled:text-slate-500 md:col-span-1 rounded-md bg-red-400 mt-2 mr-2 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                {t("User.deleted")}
              </button>
            </div>
          </div>
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              {t("User.info_personal")}
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("Table.username")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.username}
                    type="text"
                    name="username"
                    id="username"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.username && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.username}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.email")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.email}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.email && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.email}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.firstname")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.firstname}
                    type="text"
                    name="firstname"
                    id="firstname"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.date && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.date}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.lastname")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.lastname}
                    type="text"
                    name="lastname"
                    id="lastname"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.date && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.date}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.phone")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.phone}
                    type="text"
                    name="phone"
                    id="phone"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.phone && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.phone}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-white/10 pb-12">
            <p className="mt-1 text-sm leading-6 text-gray-400">
              {t("Table.created_at")}:{" "}
              {user && formatDate(user.created_at, true)}
            </p>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              {t("Table.updated_at")}:{" "}
              {user && formatDate(user.updated_at, true)}
            </p>
          </div>
        </div>

        <div className="mt-6 mx-auto grid grid-cols-2 md:grid-cols-2 gap-x-6">
          <button
            type="button"
            className="rounded-md bg-red-500 text-sm font-semibold leading-6 text-black hover:bg-indigo-400"
          >
            {t("User.button_cancel")}
          </button>
          <button
            type="submit"
            className="rounded-md bg-[#00df9a] px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            {t("User.button_save")}
          </button>
        </div>
      </form>
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default UserEdit;
