import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserDataService from "../../services/users";
import { formatDate } from "../utils/dateUtils";
import UserContext from "../../contexts/UserContext";

const DriverEdit = ({ id }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [driver, setDriver] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    role: 2,
    email: "",
    firstname: "",
    lastname: "",
    username: "",
    phone: "",
  });

  const successToast = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorToast = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const retrieveDriver = useCallback(() => {
    //console.log("get drivers");
    if (user.role === 1) {
      UserDataService.getUser(id)
        .then((response) => {
          const driver = response.data;
          setDriver(driver);
          setFormData((prevState) => ({
            ...prevState,
            ...driver,
          }));
        })
        .catch((e) => {
          console.log(e);
          errorToast(t("Error.We_can_not_contact_the_server"));
        });
    }
  }, [user.role, id, t]);

  useEffect(() => {
    retrieveDriver();
  }, [retrieveDriver]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlerrors = (errors, inputValues) => {
    // Validation logic

    if (!inputValues.firstname) {
      errors.firstname = t("Error.Firstname_is_required");
    }

    if (!inputValues.lastname) {
      errors.lastname = t("Error.Lastname_is_required");
    }

    if (!inputValues.username) {
      errors.username = t("Error.Username_is_required");
    }

    if (!inputValues.email) {
      errors.email = t("Error.Email_is_required");
    } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
      errors.email = t("Error.Email_must_be_a_valid_email");
    }

    if (!inputValues.phone) {
      errors.phone = t("Error.Phone_is_required");
    }

    return errors;
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    //console.log(formData);

    let errors = {};
    errors = handlerrors(errors, formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      //axiooooooooooooooooooooooooooooooos
      console.log("Before sending updated formData");
      try {
        const { data } = await UserDataService.updateUser(id, formData);
        const { success, message, user } = data;
        if (success) {
          successToast(t("Message.Driver_updated_successfully"));
          console.log("success:", message);
          setDriver({ ...user });
        } else {
          errorToast(t("Message.The_server_could_not_manage_the_request"));
          console.log("Not success:", message);
        }
      } catch (error) {
        errorToast(t("Message.We_can_not_contact_the_server"));
        console.log(error);
      }
    } else {
      errorToast(t("Message.There_are_errors_in_the_form"));
      //console.log(errors)
    }
  };

  return (
    <div className="text-white">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">
              {t("User.info_personal")}
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("User.username")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.username}
                    type="text"
                    name="username"
                    id="username"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.username && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.username}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("User.email")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.email}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.email && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.email}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.firstname")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.firstname}
                    type="text"
                    name="firstname"
                    id="firstname"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.date && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.date}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.lastname")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.lastname}
                    type="text"
                    name="lastname"
                    id="lastname"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.date && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.date}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("MainForm.phone")}
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.phone}
                    type="text"
                    name="phone"
                    id="phone"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                  {formErrors.phone && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formErrors.phone}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-white/10 pb-12">
            <p className="mt-1 text-sm leading-6 text-gray-400">
              {t("Table.created_at")}:{" "}
              {driver && formatDate(driver.created_at, true)}
            </p>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              {t("Table.updated_at")}:{" "}
              {driver && formatDate(driver.updated_at, true)}
            </p>
          </div>
        </div>

        <div className="mt-6 mx-auto grid grid-cols-2 md:grid-cols-2 gap-x-6">
          <button
            type="button"
            className="rounded-md bg-red-500 text-sm font-semibold leading-6 text-black hover:bg-indigo-400"
          >
            {t("User.button_cancel")}
          </button>
          <button
            type="submit"
            className="rounded-md bg-[#00df9a] px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            {t("User.button_save")}
          </button>
        </div>
      </form>
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default DriverEdit;
