import React, { useState, useContext } from "react";
import UserContext from "../contexts/UserContext";

import { AiOutlineClose, AiOutlineMenu, AiFillAlert } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import config from "../config";

const Navbar = () => {
  const { authenticated, logout } = useContext(UserContext);
  const [nav, setNav] = useState(false);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    setNav(!nav);
  };

  const handleNav = () => {
    setNav(!nav);
  };

  const company_name = config.appName || "To define";
  const phone = config.env.phone || "To define";
  const primaryColor = config.env.primaryColor || "#00df9a";

  return (
    <div className="sticky top-0 z-30 bg-black">
      <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
        <h1 className="text-3xl font-bold hover:text-indigo-400" style={{ color: primaryColor }}>
          <div className="flex items-center">
            {config.appFavicon && (
              <img src={`/${config.appFavicon}`} alt="Company Logo" className="h-8 w-auto mr-2" />
            )}
            <Link to="/" className="">
              {company_name}
            </Link>
          </div>
        </h1>
        <ul className="hidden md:flex">
          <li className="p-4">
            <Link to="/#home">{t("Navbar.home")}</Link>
          </li>
          {/*<li className="p-4">
            <Link to="/check">{t("Navbar.your_booking")}</Link>
          </li>*/}
          <li className="p-4">
            <Link to="/#about">{t("Navbar.about")}</Link>
          </li>
          <li className="p-4">
            <Link to="/#faq">{t("Navbar.faq")}</Link>
          </li>
          <li className="p-4">
            <a href={`tel:${phone}`} className="inline-flex">
              <AiFillAlert size={20} color="red" />
              {t("Navbar.emergency")}
            </a>
          </li>
          {authenticated && (
            <li className="p-4">
              <Link to="/dashboard" className="inline-flex " style={{ color: primaryColor }}>
                {t("Navbar.dashboard")}
              </Link>
            </li>
          )}
          <li className="my-auto px-2">
            <select
              className="cursor-pointer bg-transparent border border-gray-300 rounded p-2 transition duration-300 ease-in-out hover:bg-gray-600 hover:border-gray-600 hover:shadow-md"
              onChange={changeLanguage}
              value={currentLanguage}
            >
              <option value="en">English</option>
              <option value="fr">Français</option>
              <option value="es">Español</option>
              <option value="ne">Nederlands</option>
              <option value="de">Deutsch</option>
              <option value="it">Italiano</option>
              <option value="po">Português</option>
            </select>
          </li>
          <li className="my-auto">
            {authenticated ? (
              <button
                onClick={logout}
                className="bg-red-300 w-[115px] rounded-full font-medium  py-2 text-black hover:bg-indigo-400"
              >
                {t("Navbar.logout")}
              </button>
            ) : (
              <Link to="/login">
                <button className="w-[115px] rounded-full font-medium  py-2 text-black hover:bg-indigo-400" style={{ backgroundColor: primaryColor }}>
                  {t("Navbar.login")}
                </button>
              </Link>
            )}
          </li>
        </ul>

        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <div
          className={
            nav
              ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 md:hidden"
              : "fixed left-[-100%]"
          }
        >
          <h1 className="w-full text-3xl font-bold m-4" style={{ color: primaryColor }}>
            {company_name}
          </h1>
          <ul className="p-4 uppercase">
            <Link to="/#home" onClick={handleNav}>
              <li className="p-4 border-b border-gray-600">
                {t("Navbar.home")}
              </li>
            </Link>
            {/*<Link to="/check" onClick={handleNav}>
              <li className="p-4 border-b border-gray-600">
                {t("Navbar.your_booking")}
              </li>
            </Link>*/}
            <Link to="/#about" onClick={handleNav}>
              <li className="p-4 border-b border-gray-600">
                {t("Navbar.about")}
              </li>
            </Link>
            <Link to="/#faq" onClick={handleNav}>
              <li className="p-4 border-b border-gray-600">
                {t("Navbar.faq")}
              </li>
            </Link>
            <li className="p-4 border-b border-gray-600 inline-flex w-full">
              <a href={`tel:${phone}`} className="inline-flex">
                <AiFillAlert size={20} color="red" />
                {t("Navbar.emergency")}
              </a>
            </li>
            {authenticated && (
              <Link to="/dashboard" onClick={handleNav}>
                <li className="p-4 border-b border-gray-600 inline-flex w-full" style={{ color: primaryColor }}>
                  {t("Navbar.dashboard")}
                </li>
              </Link>
            )}
            <li className="p-4 border-b border-gray-600">
              <select
                className="w-full cursor-pointer bg-transparent border border-gray-300 rounded p-2 transition duration-300 ease-in-out hover:bg-gray-600 hover:border-gray-600 hover:shadow-md"
                onChange={changeLanguage}
                value={currentLanguage}
              >
                <option value="en">English</option>
                <option value="fr">Français</option>
                <option value="es">Español</option>
                <option value="ne">Nederlands</option>
                <option value="de">Deutsch</option>
                <option value="it">Italiano</option>
                <option value="po">Português</option>
              </select>
            </li>
            <Link to="/booking/departure" onClick={handleNav}>
              <li className="p-4 ">
                <button className=" w-full rounded-md font-medium  py-2 text-black hover:bg-indigo-400" style={{ backgroundColor: primaryColor }}>
                  {t("Navbar.button")}
                </button>
              </li>
            </Link>
            {authenticated ? (
              <li className="p-4 ">
                <button
                  onClick={logout}
                  className="bg-red-300 w-full rounded-md font-medium  py-2 text-black hover:bg-indigo-400"
                >
                  {t("Navbar.logout")}
                </button>
              </li>
            ) : (
              <Link to="/login" onClick={handleNav}>
                <li className="p-4 ">
                  <button className=" w-full rounded-md font-medium  py-2 text-black hover:bg-indigo-400" style={{ backgroundColor: primaryColor }}>
                    {t("Navbar.login")}
                  </button>
                </li>
              </Link>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
