import React from "react";
import UpdateUser from "../components/Admin/UserEdit";
import CreateUser from "../components/Admin/UserCreate";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { truncateText } from "../components/utils/someFunction";

const User = () => {
  const { id } = useParams();

  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto">
        <div className="bg-gray-800 pb-32">
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-[#00df9a]">
                <Link to={`/dashboard`} className="hover:text-indigo-400">
                  Dashboard
                </Link>
                {"/ "}
                <Link to={`/users`} className="hover:text-indigo-400">
                  Users
                </Link>
                {id ? `/ Edit User ${truncateText(id, 7)}` : " / Create USer"}
              </h1>
            </div>
          </header>
        </div>

        <div className="bg-gray-800 -mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-black px-5 py-6 shadow sm:px-6">
              {id ? <UpdateUser id={id} /> : <CreateUser />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
