import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
//import { BiSolidPlaneTakeOff, BiSolidPlaneLand } from "react-icons/bi";
import { getFormattedDate } from "../utils/dateUtils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
//import RideDataService from "../../services/rides";
import emailjs from '@emailjs/browser';
import countries from "../utils/countries";
import config from "../../config";

const BookRide = ({ mode = "distance", passengers = 1, vehicleType = "car" }) => {
  const { t } = useTranslation();
  const primaryColor = config.env.primaryColor || "#00df9a";
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const successToast = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const errorToast = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  //const [searchParams] = useSearchParams();
  

  const [formData, setFormData] = useState({
    serviceType: mode,
    date: getFormattedDate(),
    time: "",
    pickupLocation: "",
    dropoffLocation: "",
    transferType: "oneWay",
    extraTime: 0,
    duration: 1,
    passengers: passengers,
    suitcases: 0,
    vehicleType: vehicleType,
    firstname: "",
    lastname: "",
    email: "",
    countrycode: "",
    phone: "",
    comments: "",
  });

  const [datePicker, setDatePicker] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleDatePickerValueChange = (newValue) => {
    setDatePicker(newValue);
    setFormData({
      ...formData,
      date: newValue.startDate,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const validateForm = (inputValues) => {
    let errors = {};

    if (!inputValues.serviceType) {
      errors.serviceType = "Service type is required";
    }

    if (!inputValues.date) {
      errors.date = "Date is required";
    }

    if (!inputValues.time) {
      errors.time = "Time is required";
    }

    if (!inputValues.pickupLocation) {
      errors.pickupLocation = "Pickup location is required";
    }

    if (!inputValues.dropoffLocation && inputValues.serviceType === "distance") {
      errors.dropoffLocation = "Dropoff location is required";
    }

    if (
      inputValues.serviceType === "distance" &&
      inputValues.transferType === "withReturn" &&
      inputValues.extraTime > 15
    ) {
      errors.extraTime = "Extra time cannot exceed 15 hours";
    }

    if (inputValues.serviceType === "hourly" && inputValues.duration > 24) {
      errors.duration = "Duration cannot exceed 24 hours";
    }

    if (!inputValues.passengers || inputValues.passengers < 1) {
      errors.passengers = "Passenger number is required and must be at least 1";
    }

    if (!inputValues.vehicleType) {
      errors.vehicleType = "Vehicle type is required";
    }

    if (!inputValues.firstname) {
      errors.firstname = "Firstname is required";
    }

    if (!inputValues.lastname) {
      errors.lastname = "Lastname is required";
    }

    if (!inputValues.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
      errors.email = "Email address is invalid";
    }

    if (!inputValues.countrycode) {
      errors.countrycode = "Country code is required";
    }

    if (!inputValues.phone) {
      errors.phone = "Phone code is required";
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  
    const errors = validateForm(formData);
    setFormErrors(errors);
  
    if (Object.keys(errors).length === 0) {
      console.log("Before sending formData", formData);


      // Prepare the message string
      const message = `
        Service Type: ${formData.serviceType === "distance" ? "Distance-based" : "Hourly"}\n
        Date: ${formData.date}\n
        Time: ${formData.time}\n
        Pickup Location: ${formData.pickupLocation}\n
        ${formData.serviceType === "distance" ? `Dropoff Location: ${formData.dropoffLocation}\n` : ''}
        ${formData.serviceType === "distance" ? `Transfer Type: ${formData.transferType}\n` : ''}
        ${formData.serviceType === "distance" && formData.transferType === "withReturn" ? `Extra Time: ${formData.extraTime} hours\n` : ''}
        ${formData.serviceType === "hourly" ? `Duration: ${formData.duration} hours\n` : ''}
        Number of Passengers: ${formData.passengers}\n
        Number of Suitcases: ${formData.suitcases}\n
        Vehicle Type: ${formData.vehicleType}\n
        First Name: ${formData.firstname}\n
        Last Name: ${formData.lastname}\n
        Email: ${formData.email}\n
        Phone: ${formData.countrycode} ${formData.phone}\n
        Comments: ${formData.comments || "N/A"}
      `;

      // Prepare email data for EmailJS
      const emailData = {
        to_name: `${formData.firstname} ${formData.lastname}`,
        to_email: formData.email,
        to_bcc: process.env.REACT_APP_EMAILJS_BCC,
        message: message
      };

      try {
        // Send the email using EmailJS with environment variables
        await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,   // Service ID
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,  // Template ID
          emailData,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY    // Public Key
        );
        
        successToast("Your booking has been successfully sent!");
        console.log("Email sent successfully");
        setTimeout(() => {
          navigate("/thankyou");
        }, 3000);
      } catch (error) {
        setIsSubmitting(false);
        errorToast("We cannot send your booking request at this time.");
        console.log("Failed to send email:", error);
      }
      event.target.reset();
    } else {
      setIsSubmitting(false);
      errorToast("There are errors in the form.");
      console.log(errors);
    }
  };

  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4">
      <div className="text-white mb-7">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
          {t("MainForm.title")}
        </h1>
      </div>

      <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-12">
            <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">
              {t("MainForm.serviceSelection")}
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="serviceType" className="block text-xl font-medium text-white">
                  {t("MainForm.serviceType")}
                </label>
                <select
                  id="serviceType"
                  name="serviceType"
                  value={formData.serviceType}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="distance">{t("MainForm.distanceService")}</option>
                  <option value="hourly">{t("MainForm.hourlyService")}</option>
                </select>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="date" className="block text-xl font-medium text-white">
                  {t("MainForm.date")}
                </label>
                <Datepicker
                  value={datePicker}
                  onChange={handleDatePickerValueChange}
                  primaryColor={"green"}
                  className="dark:bg-gray-800 dark:text-white"
                  asSingle={true}
                  useRange={false}
                  minDate={new Date()}
                  maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                />
                {formErrors.date && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {formErrors.date}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="time" className="block text-xl font-medium text-white">
                  {t("MainForm.time")}
                </label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                {formErrors.time && (
                  <p className="mt-2 text-sm text-red-600" id="time-error">
                    {formErrors.time}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="pickupLocation" className="block text-xl font-medium text-white">
                  {t("MainForm.pickupLocation")}
                </label>
                <input
                  type="text"
                  id="pickupLocation"
                  name="pickupLocation"
                  value={formData.pickupLocation}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                {formErrors.pickupLocation && (
                  <p className="mt-2 text-sm text-red-600" id="pickupLocation-error">
                    {formErrors.pickupLocation}
                  </p>
                )}
              </div>

              {formData.serviceType === "distance" && (
                <>
                  <div className="sm:col-span-6">
                    <label htmlFor="dropoffLocation" className="block text-xl font-medium text-white">
                      {t("MainForm.dropoffLocation")}
                    </label>
                    <input
                      type="text"
                      id="dropoffLocation"
                      name="dropoffLocation"
                      value={formData.dropoffLocation}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                    {formErrors.dropoffLocation && (
                      <p className="mt-2 text-sm text-red-600" id="dropoffLocation-error">
                        {formErrors.dropoffLocation}
                      </p>
                    )}
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="transferType" className="block text-xl font-medium text-white">
                      {t("MainForm.transferType")}
                    </label>
                    <select
                      id="transferType"
                      name="transferType"
                      value={formData.transferType}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="oneWay">{t("MainForm.oneWay")}</option>
                      <option value="withReturn">{t("MainForm.withReturn")}</option>
                    </select>
                  </div>

                  {formData.transferType === "withReturn" && (
                    <div className="sm:col-span-6">
                      <label htmlFor="extraTime" className="block text-xl font-medium text-white">
                        {t("MainForm.extraTime")}
                      </label>
                      <input
                        type="number"
                        id="extraTime"
                        name="extraTime"
                        value={formData.extraTime}
                        onChange={handleChange}
                        min="0"
                        max="15"
                        className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                      {formErrors.extraTime && (
                        <p className="mt-2 text-sm text-red-600" id="extraTime-error">
                          {formErrors.extraTime}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}

              {formData.serviceType === "hourly" && (
                <>
                  <div className="sm:col-span-6">
                    <label htmlFor="duration" className="block text-xl font-medium text-white">
                      {t("MainForm.duration")}
                    </label>
                    <input
                      type="number"
                      id="duration"
                      name="duration"
                      value={formData.duration}
                      onChange={handleChange}
                      min="1"
                      max="24"
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                    {formErrors.duration && (
                      <p className="mt-2 text-sm text-red-600" id="duration-error">
                        {formErrors.duration}
                      </p>
                    )}
                  </div>
                </>
              )}

              <div className="sm:col-span-6">
                <label htmlFor="passengers" className="block text-xl font-medium text-white">
                  {t("MainForm.passengers")}
                </label>
                <input
                  type="number"
                  id="passengers"
                  name="passengers"
                  value={formData.passengers}
                  onChange={handleChange}
                  min="1"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                {formErrors.passengers && (
                  <p className="mt-2 text-sm text-red-600" id="passengers-error">
                    {formErrors.passengers}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="suitcases" className="block text-xl font-medium text-white">
                  {t("MainForm.suitcases")}
                </label>
                <input
                  type="number"
                  id="suitcases"
                  name="suitcases"
                  value={formData.suitcases}
                  onChange={handleChange}
                  min="0"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                {formErrors.suitcases && (
                  <p className="mt-2 text-sm text-red-600" id="suitcases-error">
                    {formErrors.suitcases}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="vehicleType" className="block text-xl font-medium text-white">
                  {t("MainForm.vehicleType")}
                </label>
                <select
                  id="vehicleType"
                  name="vehicleType"
                  value={formData.vehicleType}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="car">{t("MainForm.car")}</option>
                  <option value="van">{t("MainForm.van")}</option>
                  <option value="bus">{t("MainForm.bus")}</option>
                  <option value="coach">{t("MainForm.coach")}</option>
                </select>
                {formErrors.vehicleType && (
                  <p className="mt-2 text-sm text-red-600" id="vehicleType-error">
                    {formErrors.vehicleType}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="border-b border-white/10 pb-12">
            <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">
              {t("MainForm.personalInformation")}
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="firstname" className="block text-xl font-medium text-white">
                  {t("MainForm.firstname")}
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  required
                  autoComplete="given-name"
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                {formErrors.firstname && (
                  <p className="mt-2 text-sm text-red-600" id="firstname-error">
                    {formErrors.firstname}
                  </p>
                )}
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="lastname" className="block text-xl font-medium text-white">
                  {t("MainForm.lastname")}
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  required
                  autoComplete="family-name"
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                {formErrors.lastname && (
                  <p className="mt-2 text-sm text-red-600" id="lastname-error">
                    {formErrors.lastname}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-xl font-medium text-white">
                  {t("MainForm.email")}
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete="email"
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                {formErrors.email && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {formErrors.email}
                  </p>
                )}
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="countrycode" className="block text-xl font-medium text-white">
                  {t("MainForm.country_code")}
                </label>
                <div className="mt-2">
                          <select
                            id="countrycode"
                            name="countrycode"
                            value={formData.countrycode}
                            onChange={handleChange}
                            required
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
                          >
                            <option value="" disabled>
                              {t("MainForm.country_code_select")}
                            </option>
                            {countries.map(([country, _, __, code], index) => (
                              <option key={index} value={code}>
                                {`${country} (+${code})`}
                              </option>
                            ))}
                          </select>
                          {formErrors.countrycode && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="countrycode-error"
                            >
                              {formErrors.countrycode}
                            </p>
                          )}
                        </div>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="phone" className="block text-xl font-medium text-white">
                  {t("MainForm.phone")}
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  autoComplete="phone"
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                {formErrors.phone && (
                  <p className="mt-2 text-sm text-red-600" id="phone-error">
                    {formErrors.phone}
                  </p>
                )}
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="comments" className="block text-xl font-medium text-white">
                  {t("MainForm.comments")}
                </label>
                <textarea
                  id="comments"
                  name="comments"
                  value={formData.comments}
                  onChange={handleChange}
                  rows="3"
                  className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <p className="sm:col-span-6 text-xl leading-6 text-gray-400">
              {t("MainForm.terms")}{" "}
              <Link
                to="/terms"
                target="_blank"
                rel="noopener noreferrer"
                className=""
                style={{ color: primaryColor }}
              >
                {t("MainForm.terms_link")}
              </Link>
            </p>

            <div className="sm:col-span-6 mt-6 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className="w-full rounded-md active:bg-indigo-700 px-16 py-2 text-xl font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:text-slate-500"
                style={{ backgroundColor: primaryColor }}
                disabled={isSubmitting}
              >
                {t("Button.book")}
              </button>
            </div>

            {Object.keys(formErrors).length > 0 && (
              <p
                className="sm:col-span-6 text-xl leading-6 text-red-600"
                id="message-error"
              >
                {t("MainForm.errors")}
              </p>
            )}
          </div>
        </div>
      </form>

      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default BookRide;
