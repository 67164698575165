import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";

const Newsletter = () => {
  const primaryColor = config.env.primaryColor || "#00df9a";
  const { t } = useTranslation();
  const [passengers, setPassengers] = useState(1); // Default to 1
  const [vehicleType, setVehicleType] = useState(""); // Default to empty string
  const [mode, setMode] = useState(""); // Default to empty string

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "passenger") {
      setPassengers(value);
    } else if (name === "vehicleType") {
      setVehicleType(value);
    } else if (name === "mode") {
      setMode(value);
    }
  };

  return (
    <div className="w-full py-16 text-white px-4">
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-4">
        <div className="lg:col-span-3 my-2">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("CTA.main")}
          </h1>
          <p>{t("CTA.subtitle")}</p>
        </div>
        <div className="my-4">
          <div className="flex flex-col items-center justify-between w-full">
            <input
              name="passenger"
              onChange={handleInputChange}
              className="p-3 w-full rounded-md text-black"
              type="number"
              min="1"
              placeholder={t("MainForm.passengers")}
            />
            <select
              name="vehicleType"
              onChange={handleInputChange}
              defaultValue={"DEFAULT"}
              className="mt-3 p-3 w-full rounded-md text-black"
            >
              <option value="DEFAULT" disabled>
                {t("MainForm.vehicleType")}
              </option>
              <option value="car">{t("MainForm.car")}</option>
              <option value="van">{t("MainForm.van")}</option>
              <option value="bus">{t("MainForm.bus")}</option>
              <option value="coach">{t("MainForm.coach")}</option>
            </select>
            <select
              name="mode"
              onChange={handleInputChange}
              defaultValue={"DEFAULT"}
              className="mt-3 p-3 w-full rounded-md text-black"
            >
              <option value="DEFAULT" disabled>
                {t("CTA.mode")}
              </option>
              <option value="distance">{t("MainForm.distanceService")}</option>
              <option value="hourly">{t("MainForm.hourlyService")}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="max-w-[1240px] mx-auto">
        <Link
          to={`/booking/${mode}?passengers=${passengers}&vehicleType=${vehicleType}`}
        >
          <button
            disabled={mode !== "distance" && mode !== "hourly"}
            className="w-full rounded-md font-medium my-6 mx-auto py-3 text-black enabled:hover:bg-indigo-400 disabled:text-slate-500"
            style={{ backgroundColor: primaryColor }}
          >
            {t("Button.cta")}
          </button>
        </Link>
        <p>
          {t("CTA.note")}{" "}
          <Link to="/privacy">
            <span style={{ color: primaryColor }}>{t("CTA.link")}.</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Newsletter;
