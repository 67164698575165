import React from "react";
import UpdateDriver from "../components/Admin/DriverEdit";
import CreateDriver from "../components/Admin/DriverCreate";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { truncateText } from "../components/utils/someFunction";
import { useTranslation } from "react-i18next";

const Driver = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto">
        <div className="bg-gray-800 pb-32">
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-[#00df9a]">
                <Link to={`/dashboard`} className="hover:text-indigo-400">
                  {t("Navbar.dashboard")}
                </Link>
                {"/ "}
                <Link to={`/drivers`} className="hover:text-indigo-400">
                  {t("DriversPage.link")}
                </Link>
                {id
                  ? `/ ${t("User.edit_driver")} ${truncateText(id, 7)}`
                  : ` / ${t("User.create_driver")}`}
              </h1>
            </div>
          </header>
        </div>

        <div className="bg-gray-800 -mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-black px-5 py-6 shadow sm:px-6">
              {id ? <UpdateDriver id={id} /> : <CreateDriver />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driver;
