import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import UserDataService from "../../services/users";
import UserContext from "../../contexts/UserContext";

import { useNavigate } from "react-router-dom";

import {
  truncateText,
  roleLabel,
  capitalizeFirstLetter,
} from "../utils/someFunction";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";

const Users = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);

  function edit_user(id) {
    navigate(`/user/${id}/edit`);
  }

  function create_user() {
    navigate("/user/create");
  }

  const errorToast = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const retrieveUser = useCallback(() => {
    //console.log("get drivers");
    if (user.role === 1) {
      UserDataService.getAllUsers()
        .then((response) => {
          //console.log(response.data);
          setUsers(response.data);
        })
        .catch((e) => {
          console.log(e);
          errorToast("We can not conctact the server.");
        });
    }
  }, [user.role]);

  useEffect(() => {
    retrieveUser();
  }, [retrieveUser]);

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-white">
            {t("UsersPage.link")}
          </h1>
          <p className="mt-2 text-sm text-gray-300">{t("UsersPage.message")}</p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-0 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-0">
          <div className="flex mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={create_user}
              className="block rounded-md bg-[#00df9a] mx-3 px-3 py-2 text-center text-sm font-semibold text-black hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              {t("UsersPage.button_add")}
            </button>
            <button
              type="button"
              className="block rounded-md bg-[#00df9a] px-3 py-2 text-center text-sm font-semibold text-black hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              {t("UsersPage.button_pass")}
            </button>
          </div>
          <div className="pt-5 inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {users.map((user, index) => (
                <li
                  key={user.email}
                  className="col-span-1 divide-y divide-gray-200 rounded-lg bg-black shadow border"
                >
                  <div
                    className="flex w-full items-center justify-between space-x-6 p-6  cursor-pointer hover:bg-gray-800"
                    onClick={() => edit_user(user._id)}
                  >
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-gray-300">
                          {user.username}
                          {" | "}
                          {capitalizeFirstLetter(user?.lastname)}{" "}
                          {capitalizeFirstLetter(user?.firstname)}
                        </h3>
                        <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {roleLabel(user.role)}
                        </span>
                      </div>
                      <p className="mt-1 truncate text-sm text-gray-500">
                        {user.email}
                      </p>
                      <p className="mt-1 truncate text-sm text-gray-500">
                        {user.phone}
                      </p>
                      <p className="mt-1 truncate text-sm text-gray-500">
                        {truncateText(user._id, 10)}
                      </p>
                    </div>
                    <img
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                      src={`https://www.gravatar.com/avatar/${CryptoJS.MD5(
                        user.email.trim().toLowerCase()
                      ).toString()}?d=identicon`}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                      <div className="flex w-0 flex-1 cursor-pointer hover:bg-gray-800">
                        <a
                          href={`mailto:${user.email}`}
                          className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-500"
                        >
                          <EnvelopeIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {t("Table.email")}
                        </a>
                      </div>
                      <div className="-ml-px flex w-0 flex-1 cursor-pointer hover:bg-gray-800">
                        <a
                          href={`tel:${user.phone}`}
                          className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-500"
                        >
                          <PhoneIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {t("MainForm.phone")}
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default Users;
